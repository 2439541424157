<template>
  <v-app class="responsive-layout">
    <Header />
    <v-main class="mb-16">
      <router-view/>
    </v-main>

    <Footer v-if="isHomePage"/>

    <v-bottom-navigation
        v-model="activeTab"
        color="main"
        width="100%"
        fixed
        shift
        grow
        background-color="#eee"
        height="60"
    >
      <v-btn
          link
          to="/"
          value="home"
          height="60"
          text
          large
      >
        <span>خانه</span>
        <v-icon>
          mdi-home
        </v-icon>
      </v-btn>

      <v-btn
          link
          to="/files/search"
          value="search"
          height="60"
          text
          large
      >
        <span>جستجو فایل</span>
        <v-icon>mdi-magnify</v-icon>
      </v-btn>

      <v-btn
          link
          to="/pricing"
          value="pricing"
          height="60"
          text
          large
      >
        <span>اشتراک ها</span>

        <v-icon>mdi-package-variant-closed</v-icon>
      </v-btn>

      <v-btn
          link
          to="/panel/dashboard"
          value="profile"
          height="60"
          text
          large
      >
        <span>پروفایل</span>

        <v-icon>mdi-account</v-icon>
      </v-btn>

    </v-bottom-navigation>

  </v-app>
</template>

<script>
import Header from "@/components/Header"
import Footer from "@/components/Footer"

export default {
  name: 'ResponsiveLayout',
  components: {
    Header,
    Footer
  },

  computed: {
    isHomePage() {
      return this.$route.path === '/';
    },
  },

  data() {
    return {
      activeTab: 'home'
    }
  },

}
</script>